import React, {useEffect} from 'react';
import routes from "./routes";
import {RouterProvider} from "react-router-dom";
import "./App.css";
import {useDispatch} from 'react-redux';
import {
  GET_ALL_ZONES_PLANT_MARKERS,
  GET_LAST_30_DAYS_STATS,
  GET_LAST_90_DAYS_STATS,
  GET_TOPPERS,
  GET_ZONES
} from './redux/actions/action';
import {getZones} from './services/zone';
import {getPlantsLocation} from './services/location';
import {fetchStats, fetchToppers} from './services/stats';

function App() {
  const dispatch: any = useDispatch();
  useEffect(() => {

    /**
     * Fetches data from different APIs and dispatches the results to the Redux store.
     */
    const fetchData = async () => {
      try {
        getPlantsLocation('1,2,3,4,5,6,7,8')
        .then((plantsLocationResult) => {
          dispatch({ type: GET_ALL_ZONES_PLANT_MARKERS, payload: plantsLocationResult ? (plantsLocationResult as { data: {} }).data : {} });
        })
        .catch(error => {
          console.log("Error Fetching Markers:", error);
        });
        
        getZones()
          .then((zonesResult) => {
            dispatch({ type: GET_ZONES, payload: zonesResult ? (zonesResult as { data: {} }).data : {} });
          })
          .catch(error => {
            console.log("Error Fetching Zones:", error);
          });

          fetchStats('FEEDBACK', 30)
            .then((thirtyDaysStats) => {
              dispatch({ type: GET_LAST_30_DAYS_STATS, payload: thirtyDaysStats ? (thirtyDaysStats as { data: {} }).data : {} });

            })
            .catch(error => {
              console.log("Error Fetching Stats:", error);
            });

        fetchStats('FEEDBACK', 90)
          .then((ninetyDaysStats) => {
            dispatch({ type: GET_LAST_90_DAYS_STATS, payload: ninetyDaysStats ? (ninetyDaysStats as { data: {} }).data : {} });

          })
          .catch(error => {
            console.log("Error Fetching Stats:", error);
          })

          fetchToppers()
          .then((toppers) => {
            dispatch({ type: GET_TOPPERS, payload: toppers ? (toppers as { data: {} }).data : [] });
          })
          .catch(error => {
            console.log("Error Fetching Toppers List:", error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <div className="App">
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
