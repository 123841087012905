import React, {Fragment, useRef} from "react";
import {Main, Statistics, Swiper} from "../../components";
import {useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import './style.scss'

/**
 * Renders the Homepage component.
 */
const Homepage = () => {
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Scrolls to the stats section.
   */
  const scrollToStats = () => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  return (
    <Fragment>
        <div id='home' className={pathname === '/' ? 'd-block' : 'd-none'}>
          <Main handleClick={scrollToStats} />
          <Box className='swiper-container'>
          <Swiper/>
          </Box>
          <Statistics reference={ref} />
        </div>
    </Fragment>
  );
};

export default Homepage;
