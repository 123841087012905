import { TableCell , tableCellClasses,styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.root}`]: {
    background: "#01382E",
    padding: "10px 12px",
  },
  [`&.${tableCellClasses.head}`]: {
    color: "#fff",
    "&:nth-of-type(2)": {
      borderLeft: "1px solid #B9B9B9",
      borderRight: "1px solid #B9B9B9",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default StyledTableCell;