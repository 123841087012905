import {GET_LAST_30_DAYS_STATS, GET_LAST_90_DAYS_STATS, GET_TOPPERS} from "../actions/action";

const initialState: any = {
    thirtyDaysStats: [],
    ninetyDaysStats: []
};

/**
 * Reducer function that handles the state for the thirty Days Stats property.
 *
 * @param state - The current state of the thirtyDaysStats property.
 * @param action - The action object containing the type and payload.
 * @return The updated state of the thirtyDaysStats property.
 */
const getThirtyDaysStatsReducer = (state = initialState.thirtyDaysStats, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_LAST_30_DAYS_STATS: {
            return action.payload
        }
        default:
            return state
    }
}

/**
 * Reducer function that handles the state for the 'ninety Days Stats' property.
 *
 * @param state - The current state of the 'ninetyDaysStats' property.
 * @param action - The action object that contains the type and payload.
 * @param action.type - The type of the action.
 * @param action.payload - The payload of the action.
 * @return The updated state of the 'ninetyDaysStats' property.
 */
const getNinetyDaysStatsReducer = (state = initialState.ninetyDaysStats, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_LAST_90_DAYS_STATS: {
            return action.payload
        }
        default:
            return state
    }
}

/**
 * Reducer function for getting the toppers.
 *
 * @param state - The current state of the toppers.
 * @param action - The action object containing the type and payload.
 * @param action.type - The type of action.
 * @param action.payload - The payload of the action.
 * @return The new state of the toppers.
 */
const getToppersReducer = (state = initialState.ninetyDaysStats, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_TOPPERS: {
            return action.payload
        }
        default:
            return state
    }
}
export { getThirtyDaysStatsReducer, getNinetyDaysStatsReducer, getToppersReducer };
