import {GET_ALL_ZONES_PLANT_MARKERS, GET_PLANT_MARKERS} from "../actions/action";

const initialState: any = {
    plantMarker: {},
    allZonesPlantMarker: {}
};

/**
 * Returns the plant market reducer based on the given state and action.
 *
 * @param state - The current state of the reducer.
 * @param action - The action object with type and payload.
 * @return The updated state based on the action type.
 */
const getPlantMarketReducer = (state = initialState.plantMarker, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_PLANT_MARKERS: {
            return action.payload
        }
        default:
            return state
    }
};

/**
 * Retrieves all plant markers for the zones from the state.
 *
 * @param state - The current state of the application.
 * @param action - The action object containing the type and payload.
 * @param action.type - The type of the action.
 * @param action.payload - The payload of the action.
 * @return The updated state with all plant markers for the zones.
 */
const getAllZonesPlantMarketReducer = (state = initialState.allZonesPlantMarker, action: { type: string, payload: [] }) => {
    switch (action.type) {
        case GET_ALL_ZONES_PLANT_MARKERS: {
            return action.payload
        }
        default:
            return state
    }
};
export { getPlantMarketReducer, getAllZonesPlantMarketReducer };
