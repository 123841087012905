import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography,} from "@mui/material";
import React, {Fragment, useState} from "react";
import {filterIcon, treeDecidious} from "../../assets/images";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {AccordionProps} from "../../interfaces";
import Loader from "../Loader";
import {openUrl} from "../../constants";
import {useSelector} from "react-redux";
import {getText} from "../../utils";

// TODO: uncomment this after updating .env on live.
let baseUrl = process.env.REACT_APP_BASEURL;

/**
 * Renders a custom accordion component.
 *
 * @param props - The props object containing details, type, currentPage, loading, and handleFilterIconClick.
 * @return The rendered custom accordion component.
 */
const CustomAccordion = ({
  details,
  type,
  currentPage,
  loading,
  handleFilterIconClick,
}: AccordionProps) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getText(language);
  const [expanded, setExpanded] = useState<string | false>(false);

  const startIndex = (currentPage - 1) * 20;
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (panel === "staticPanel") {
        return;
      }
      setExpanded(isExpanded ? panel : false);
    };
    // ! /// Newly added
    if (baseUrl?.endsWith("/")) {
      // Remove the last character (which is the slash)
      baseUrl = baseUrl?.slice(0, -1);
    }
    // ! //////////////

  return (
    <Fragment>
      <Box paddingX={"24px"}>

        {/* Using it as a header */}
        <Accordion expanded={false}>
          <AccordionSummary
            sx={{
              minHeight: '0px',
              '& .MuiAccordionSummary-content': {
                margin: '0px',
              },
            }}
            expandIcon={
              <img
                src={filterIcon}
                alt="filter-icon"
                onClick={handleFilterIconClick}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{ width: "33%", flexShrink: 0 }}
              fontWeight="bold"
              color="#150C06"
            >
              {staticTexts['serialNumber']}
            </Typography>
            <Typography fontWeight="bold" color="#150C06">
              {type}
            </Typography>
          </AccordionSummary>
        </Accordion>

      </Box>

      {/* Using it as rows */}
      <Box height={`calc(100% - 370px)`} sx={{ overflowY: 'scroll', paddingX: '24px' }}>
        {loading && <Loader />}
        {(details || []).map((detail, index) => (
          <Accordion
            key={index}
            sx={{
              "&.MuiAccordion-root:before": {
                backgroundColor: index === 0 ? "transparent" : "",
              },
            }}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {startIndex + index + 1}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {detail?.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex" }}>
              <Box width="33%"></Box>
              <Box
                width="67%"
                display="flex"
                justifyContent="flex-start"
                gap="10%"
                alignItems="center"
              >
                <div>
                  <img
                    className="dialog-icons mr-1"
                    src={treeDecidious}
                    alt="tree-icon"
                  />
                  <span>{detail?.treeCount} {staticTexts['plants']}</span>
                </div>
                {detail?.images && detail?.agreements && (
                  <Fragment>
                    <div>
                      {(detail?.images.slice(0,3) || [])?.map((image, index) => (
                        image !== '' && (
                          <img
                            key={index}
                            className="dialog-icons mr-1"
                            src={`${baseUrl}${image}`}
                            alt={'School'}
                            style={{ height: '50px', width: '50px' }}
                          />
                          )
                      ))}
                    </div>
                    <Button onClick={() => openUrl(String(`${baseUrl}${detail && detail?.agreements ? detail?.agreements[0] : ''}`))}>{staticTexts['aggrement']}</Button>
                  </Fragment>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Fragment>
  );
};

export default CustomAccordion;
