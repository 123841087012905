import React, {Fragment} from "react";
import {Box} from "@mui/material";
import Rank from "../Rank";
import {useSelector} from "react-redux";

/**
 * Renders the top students component.
 */
const TopStudents = () => {
  const topContractor = useSelector((stats: any) => stats.toppers);
  const array = topContractor?.topContractors;
  
  return (
    <Fragment>
      <Box width='100%' position='relative' minWidth='300px'>
        <Box display='flex' justifyContent='center'>
          {(array && array[0]) && <Rank index={1} name={ array[0]?.name ? array[0]?.name : '--'} zone={array[0]?.zonename ? array[0]?.zonename : '--'} count={ array[0]?.count ? array[0]?.count : '--'} image={ array[0]?.image ? array[0]?.image : ''} crowned />}
        </Box>

        <Box display='flex' justifyContent='space-between' position='absolute' width='100%' top='50%'>
          {(array && array[1]) && <Rank index={2} name={ array[1]?.name ? array[1]?.name : '--'} zone={array[1]?.zonename ? array[1]?.zonename : '--'} count={array[1]?.count ? array[1]?.count : '--'} image={ array[1]?.image ? array[1]?.image : ''} />}
          {(array && array[2]) && <Rank index={3} name={ array[2]?.name? array[2]?.name : '--'} zone={array[2]?.zonename? array[2]?.zonename : '--'} count={array[2]?.count ? array[2]?.count : '--'} image={ array[2]?.image ? array[2]?.image : ''} />}
        </Box>
      </Box>

    </Fragment>
  );
};


export default TopStudents;
