import React from "react";
import {Box} from "@mui/material";
import {CardProps} from "../../interfaces";
import "./style.scss";
import {useSelector} from "react-redux";
import {getText} from "../../utils";

/**
 * Renders a card component with the given image source, number of trees, zone, and click event handler.
 *
 * @param imgSrc - The source of the image.
 * @param trees - The number of trees.
 * @param zone - The zone.
 * @param handleClick - The click event handler.
 * @return The rendered card component.
 */
const Cards = ({ imgSrc, trees, zone, handleClick, treeData }: CardProps) => {
  const language = useSelector((state:any)=>state.language);
const staticTexts = getText(language);
  return (
    <Box
      id="statistic-card"
      className="card-wrapper cursor-pointer"
      onClick={handleClick}
    >
      <div className="tree-image-container">
        <img className="tree-image" src={imgSrc} alt="tree-img" />
      </div>
      <p className="planted-tree-data font-md">{staticTexts['treesPlanted']} : {trees}</p>
      {treeData?.total && treeData?.currentFY?.total && (
        language === 'en' ? (
          <>
            <p className="planted-tree-data font-md">{staticTexts['treePlantedCurrentFY']} {new Date().getMonth() < 3 ? `${new Date().getFullYear()-1}-${new Date().getFullYear()}` : `${new Date().getFullYear()}-${new Date().getFullYear()+1}`} : {treeData?.currentFY?.total}</p>
            <p className="planted-tree-data font-md">
              {staticTexts['treePlantedPreviousFY']} {new Date().getMonth() < 3 ? 
                `${new Date().getFullYear()-2}-${new Date().getFullYear()-1}` : 
                `${new Date().getFullYear()-1}-${new Date().getFullYear()}`} : {treeData?.previousFY?.total}
            </p>
            <p className="planted-tree-data font-md">{staticTexts['geoTaggedTrees']} : {treeData?.tagged}</p>
          </>
        ) : (
          <>
            <p className="planted-tree-data font-md">{new Date().getMonth() < 3 ? `${new Date().getFullYear()-1}-${new Date().getFullYear()}` : `${new Date().getFullYear()}-${new Date().getFullYear()+1}`} {staticTexts['treePlantedCurrentFY']} : {treeData?.currentFY?.total}</p>
            <p className="planted-tree-data font-md">
              {new Date().getMonth() < 3 ? 
                `${new Date().getFullYear()-2}-${new Date().getFullYear()-1}` : 
                `${new Date().getFullYear()-1}-${new Date().getFullYear()}`} {staticTexts['treePlantedPreviousFY']} : {treeData?.previousFY?.total}
            </p>
            <p className="planted-tree-data font-md">{staticTexts['geoTaggedTrees']} : {treeData?.tagged}</p>
          </>
        )
      )}
      <Box className="zone">{zone}</Box>
    </Box>
  );
};

export default Cards;
