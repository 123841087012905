import React, {Fragment, useState} from "react";
import {RankProps} from "../../interfaces";
import {Box, Typography} from "@mui/material";
import {crown, dumbleIcon, personIcon} from "../../assets/images";
import "./style.scss";
import {useSelector} from "react-redux";
import {getText} from "../../utils";

const baseUrl = process.env.REACT_APP_BASEURL;

/**
 * Renders a rank component.
 *
 * @param name - The name of the rank.
 * @param index - The index of the rank.
 * @param zone - The zone of the rank.
 * @param count - The count of the rank.
 * @param crowned - Whether the rank is crowned.
 * @param image - The image of the rank.
 * @return The rendered rank component.
 */
const Rank = ({ name, index, zone, count, crowned, image }: RankProps) => {

  const language = useSelector((state:any)=>state.language);
  const staticTexts = getText(language);
  const [error, setError] = useState(false);

  /**
   * Handles the error.
   */
  const handleError = () => {
    setError(true);
  }
  return (
    <Fragment>
      <Box id="rank" position="relative">
        <img
          className={!crowned ? "d-none" : "crown-image"}
          src={crown}
          alt="crown"
        />
        <Box className="student-image-container">
          <img
            className="student-image"
            height="85px"
            width="85px"
            src={error ? personIcon : `${baseUrl}${image}`}
            alt="contractor"
            onError={handleError}
          />
        </Box>
        <Box className="rank-ellipse">{index}</Box>
        <Box marginTop="16px" position="absolute" width='100%' left='2%' textAlign={'center'}>
          <Typography className="fw-700 text-white"> {name}</Typography>
          <Typography className="font-md text-white">{`${zone} ${staticTexts['zone']}`}</Typography>
          {count !== '--' && <img src={dumbleIcon} alt="dumble-icon" style={{ marginRight: '8px' }} />}
          <span className="text-white" >{count !== '--' ? `${count} pts` : count}  </span>
        </Box>
      </Box>
    </Fragment>
  );
};

Rank.defaultProps = {
  crowned: false,
};

export default Rank;
