import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert,
  Paper,
  ThemeProvider,
  createTheme,
  CssBaseline,
  InputAdornment,
  IconButton,
  Grid,
  LinearProgress,
} from "@mui/material";
import { styled, keyframes } from "@mui/system";
import {
  LockOutlined,
  PersonOutline,
  Visibility,
  VisibilityOff,
  CalendarToday,
} from "@mui/icons-material";

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#6C63FF",
    },
    secondary: {
      main: "#FF6584",
    },
    background: {
      default: "#F0F2F5",
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  shape: {
    borderRadius: 12,
  },
});

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(4, 6),
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
  borderRadius: "24px",
  // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  border: "1px solid rgba(255, 255, 255, 0.18)",
  transition: "all 0.3s ease-in-out",
  animation: `${fadeIn} 0.5s ease-out`,
  "&:hover": {
    transform: "translateY(-5px)",
    // boxShadow: "0 12px 40px 0 rgba(31, 38, 135, 0.45)",
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  animation: `${float} 3s ease-in-out infinite`,
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  //  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  backgroundColor: "#025f4e",
  border: 0,
  borderRadius: "50px",
  boxShadow: "0 3px 5px 2px rgba(108, 99, 255, .3)",
  color: "white",
  height: 48,
  padding: "0 30px",
  transition: "all 0.3s ease-in-out",
  // Responsive styles
  [theme.breakpoints.down("sm")]: {
    height: 40, // Adjust height for small screens
    padding: "20px 20px", // Adjust padding for small screens
  },
  [theme.breakpoints.up("md")]: {
    height: 50, // Adjust height for medium screens
    padding: "0 40px", // Adjust padding for medium screens
  },
  "&:hover": {
    background: `#01382e`,
    transform: "scale(1.05)",
  },
  "&:disabled": {
    backgroundColor: "#cccccc",
    cursor: "not-allowed",
    transform: "none"
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      transition: "border-color 0.3s ease-in-out",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface LoginFormData {
  email: string;
  password: string;
  startDate: string;
  endDate: string;
}

const AdminLogin: React.FC = () => {
  const [formData, setFormData] = useState<LoginFormData>({
    email: "",
    password: "",
    startDate: "",
    endDate: ""
  });
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: boolean }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setValidationErrors((prev) => ({ ...prev, [name]: false }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setDownloadProgress(0);
    
    const newValidationErrors: { [key: string]: boolean } = {};

    if (!formData.email) newValidationErrors.email = true;
    if (!formData.password) newValidationErrors.password = true;

    if (formData.endDate && !formData.startDate) {
      setError("Start date is required when end date is provided");
      return;
    }

    if (formData.startDate && formData.endDate && formData.endDate < formData.startDate) {
      setError("End date cannot be earlier than start date");
      return;
    }

    setValidationErrors(newValidationErrors);

    // Check for validation errors
    if (Object.keys(newValidationErrors).length > 0) {
      setError("Please fill in all required fields");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASEURL}admin/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.email,
          password: formData.password,
          startDate: formData.startDate,
          endDate: formData.endDate
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || "An error occurred while downloading the report.");
        return;
      }

      const filename = response.headers.get("actual-filename") || 'report.csv';
      const contentLength = response.headers.get("Content-Length");
      const total = contentLength ? parseInt(contentLength, 10) : 0;

      // Create a temporary array to store chunks
      const chunks: Uint8Array[] = [];
      let receivedLength = 0;

      // Read the response as a stream
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Failed to initialize download stream");
      }

      // Read chunks
      while (true) {
        const { done, value } = await reader.read();
        
        if (done) break;
        
        chunks.push(value);
        receivedLength += value.length;
        
        if (total) {
          const progress = (receivedLength / total) * 100;
          setDownloadProgress(Math.round(progress));
        }
      }

      // Combine chunks into a single Uint8Array
      const allChunks = new Uint8Array(receivedLength);
      let position = 0;
      for (const chunk of chunks) {
        allChunks.set(chunk, position);
        position += chunk.length;
      }

      // Create blob and trigger download
      const blob = new Blob([allChunks], { type: 'application/octet-stream' });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      setDownloadProgress(100);

    } catch (error) {
      console.error("Download error:", error);
      setError("Failed to download report. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // const isValidEmail = (email: string): boolean => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs" sx={{ py: 4 }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "calc(100vh - 140px)" }}
        >
          <Grid item xs={12}>
            <StyledPaper elevation={6}>
              <LogoContainer style={{ background: "#025f4e" }}>
                <LockOutlined fontSize="large" style={{ color: "white" }} />
              </LogoContainer>
              <Typography
                component="h1"
                variant="h5"
                gutterBottom
                style={{ fontWeight: 600 }}
              >
                Download Report
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                align="center"
                gutterBottom
              >
                Please enter your credentials and select the date range to download the report
              </Typography>
              <StyledForm onSubmit={handleSubmit} noValidate>
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Admin Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={formData.email}
                  onChange={handleInputChange}
                  error={validationErrors.email}
                  helperText={validationErrors.email ? "This field is required" : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonOutline color="action" />
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Admin Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={validationErrors.password}
                  helperText={validationErrors.password ? "This field is required" : ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="startDate"
                  label="Start Date"
                  type="date"
                  id="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday color="action" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                />
                <StyledTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="endDate"
                  label="End Date"
                  type="date"
                  id="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday color="action" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0],
                    min: formData.startDate || undefined
                  }}
                />
                {error && (
                  <Box mt={2}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
                {downloadProgress > 0 && downloadProgress < 100 && (
                  <Box mt={2}>
                    <LinearProgress 
                      variant="determinate" 
                      value={downloadProgress} 
                      sx={{ 
                        height: 10, 
                        borderRadius: 5,
                        backgroundColor: 'rgba(2, 95, 78, 0.2)',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#025f4e',
                        }
                      }} 
                    />
                    <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                      Downloading: {downloadProgress}%
                    </Typography>
                  </Box>
                )}
                <SubmitButton 
                  type="submit" 
                  fullWidth 
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 
                    (downloadProgress > 0 ? 'Downloading...' : 'Processing...') : 
                    'Download Report'
                  }
                </SubmitButton>
              </StyledForm>
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default AdminLogin;
