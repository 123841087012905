import {httpRequest} from "../network"

const fetchZoneWiseTreeDetails = () => new Promise((resolve, reject) => {
    httpRequest('GET', `public/tagged-trees-count`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { fetchZoneWiseTreeDetails };
