import React from "react";
import {LabelProps} from "../../interfaces";
import {Box, Typography} from "@mui/material";
import {crownTilted} from "../../assets/images";
import "./style.scss";

/**
 * Renders a label component.
 *
 * @param index - The index of the label.
 * @param crowned - Whether the label is crowned or not.
 * @param name - The name for the label.
 * @param schoolName - The name of the school for the label.
 * @return The rendered label component.
 */
const Label = ({ index, crowned, name, schoolName }: LabelProps) => {

  return (
    <Box id="custom-label">
      <img
        className={!crowned ? "d-none" : "crown-tilted-image"}
        src={crownTilted}
        alt="crown-tilted"
      />
      <Box
        className="d-flex items-center"
        gap="16px"
        position={crowned ? "absolute" : "static"}
        left="5%"
        width='90%'
        top='15%'
      >
        <p className="fw-700">{index + 1}</p>

        <Box textAlign="left" >
          <Typography className="fw-700">{name}</Typography>
          <Typography>{schoolName}</Typography>
        </Box>
      </Box>

      <Box
        className="d-flex justify-center items-center"
        justifySelf="flex-end"
      >
        10pts
      </Box>
    </Box>
  );
};

Label.defaultProps = {
  crowned: false,
};

export default Label;
