import {httpRequest} from "../network"

/**
 * Fetches the list of schools based on the specified zone ID and page number.
 *
 * @param zoneId - The ID of the zone.
 * @param [page] - The page number (optional, default is 1).
 * @return A promise that resolves to the fetched data.
 */
const fetchSchoolList = (zoneId: string,page?:number) => new Promise((resolve, reject) => {
    httpRequest('GET', `public/school?page=${page ? page : 1}&limit=20&zone=[${zoneId}]`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { fetchSchoolList };
