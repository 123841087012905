import { TableRow,styled } from '@mui/material';

const StyledTableRow = styled(TableRow)(() => ({
    "& td": {
      wordWrap: "break-word",
      width: "33.33%",
      border: "0.5px solid #B9B9B9",
    },
    "&:hover": {
      backgroundColor: "#f7f7f7",
      cursor: "pointer",
    },
  }));

export default StyledTableRow;
