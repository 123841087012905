import React from "react";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import {scrollLeft, scrollRight,} from "../../assets/images";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./style.scss";
import {swiperImages as images} from '../../constants/index';

/**
 * Renders a custom Swiper component.
 */
const CustomSwiper = () => {

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={24}
      slidesPerView={1}
      centeredSlides
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      loop
      breakpoints={{
        600: {
          slidesPerView: 2, // slide perview on larger screen screen (600 or more)
        },
      }}
    >
      {/* images that will be shown in slides */}
      {images.map((image: any, index: number) => (
        <SwiperSlide key={index}>
          <div className="swiper-slide-content">
            <img
              style={{ objectFit: "cover" }}
              height="388px"
              width="100%"
              src={image.imgSrc}
              alt={image.alt}
            />
          </div>
        </SwiperSlide>
      ))}

      {/* Custom buttons for swiping left and right */}
      <div className="swiper-button-prev">
        <img src={scrollLeft} alt="scroll-prev" />
      </div>

      <div className="swiper-button-next">
        <img src={scrollRight} alt="scroll-next" />
      </div>
    </Swiper>
  );
};

export default CustomSwiper;
