import React, {Fragment, useEffect, useState} from "react";
import Cards from "../Cards";
import {Dialog, Loader} from "..";
import {Box} from "@mui/material";
import MapComponent from "../Map";
import {cardDetails} from "../../constants";
import "./style.scss";
import {StatisticProps} from "../../interfaces";
import {useDispatch, useSelector} from "react-redux";
import {getPlantsLocation} from "../../services/location";
import {GET_PLANT_MARKERS, GET_SCHOOL_LIST, GET_STREET_LIST} from "../../redux/actions/action";
import {fetchSchoolList} from "../../services/school";
import {fetchStreetList} from "../../services/street";
import {fetchZoneWiseTreeDetails} from "../../services/tree_tagging";

/**
 * Renders the Statistics component.
 *
 * @param reference - the reference for the component
 * @return the rendered JSX element
 */
const Statistics = ({ reference }: StatisticProps) => {
  const dispatch: any = useDispatch();
  const [details, setDetails] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [treeDetails, setTreeDetails] = useState<any>(null);
  const allZoneMarkerDetails = useSelector((state: any) => state.allZonesMarkers);

  const [dialogHeader, setDialogHeader] = useState({
    zone: '',
    treeCount: '',
    zoneId: ''
  });

  let zoneDetails = useSelector((state: any) => state.zones);

  useEffect(() => {
    const updatedZoneDetails = zoneDetails?.map((zone: any, index: number) => ({
      ...zone,
      imgSrc: cardDetails[index]?.imgSrc || '',
      treeDetails: treeDetails?.find((item: any) => item.zoneId === zone.id) || {}
    }));
    setDetails(updatedZoneDetails);
  }, [zoneDetails, treeDetails])

  useEffect(() => {
    fetchZoneWiseTreeDetails()
      .then((result: any) => {
        setTreeDetails(result.data.zoneWise);
      })
      .catch((error) => {
        // console.error("Error fetching tree details:", error);
      });
  }, []);

  /**
   * Handles the click event.
   *
   * @param id - The ID of the item being clicked.
   * @param name - The name of the item being clicked.
   * @param count - The count of the item being clicked.
   */
  const handleClick = (id: string, name: string, count:string) => {
    setLoading(true);
    const promises = [
      getPlantsLocation(id),
      fetchSchoolList(id),
      fetchStreetList(id)
    ];

    Promise.all(promises)
      .then((results: any) => {
        const [plantsLocationResult, schoolListResult, streetListResult] = results;

        dispatch({ type: GET_PLANT_MARKERS, payload: plantsLocationResult ? { ...plantsLocationResult.data } : {} });

        dispatch({ type: GET_SCHOOL_LIST, payload: schoolListResult ? { ...schoolListResult.data } : {} });

        dispatch({ type: GET_STREET_LIST, payload: streetListResult ? { ...streetListResult.data } : {} });

        setDialogHeader({
          zone: name,
          treeCount: count,
          zoneId:id
        });
        setLoading(false);
        setOpenDialog((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
      }
      );
  };

  /**
   * Closes the dialog.
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Fragment>
      <Box ref={reference} id="details" style={{ position: 'relative' }}>
        {loading && <Loader />}
        {/* open street map component and cards */}
        <MapComponent markers={allZoneMarkerDetails?.data} />
        <Box className="detail-card-container" marginTop="2rem">
          {details?.map((card: any, index: number) => (
            <Cards
              key={index}
              imgSrc={card.imgSrc}
              zone={card.name}
              trees={card.treeCount}
              treeData={card.treeDetails}
              handleClick={() => handleClick(card.id, card.name , card.treeCount)}
            />
          ))}
        </Box>

        <Dialog name={dialogHeader.zone
        } treeCount={Number(dialogHeader.treeCount)} zoneId={dialogHeader.zoneId} isOpen={openDialog} onClose={handleCloseDialog} />
      </Box>
    </Fragment>
  );
};

export default Statistics;
