import React from "react";
import {treeMarker} from "../../assets/images";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import Library from "leaflet";
import "./style.scss";
import {Map} from "../../interfaces";
import {urls} from "../../constants";

const customIcon = new Library.Icon({
  iconUrl: treeMarker,
  iconSize: [32, 32], // size of the icon
  iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
  popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
});

/**
 * Renders a map component with custom zoom controls and markers.
 *
 * @param markers - An array of markers to be displayed on the map.
 * @param height - The height of the map component.
 * @param width - The width of the map component.
 * @return The rendered map component.
 */
const MapComponent = ({ markers,height, width }: Map) => {

  /**
   * A function to handle zooming in on the map.
   *
   * @param map - The map object to zoom in on.
   * @return This function does not return anything.
   */
  const handleZoomIn = (map: any) => {
    map.zoomIn();
  };

  /**
   * Handles zooming out of the map.
   *
   * @param map - The map object.
   */
  const handleZoomOut = (map: any) => {
    map.zoomOut();
  };
 
  //customizing zoom in and zoom out button
  const CustomZoomControl = () => {
     const map = useMap();

    return (
      <div className="custom-zoom-control">
        {/* zoom-in */}
        <button
          className="zoom-in"
          title="Zoom In"
          onClick={() => handleZoomIn(map)}
        ></button>
        {/* zoom-out */}
        <button
          className="zoom-out"
          title="Zoom Out"
          onClick={() => handleZoomOut(map)}
        ></button>
      </div>
    );
  };

  return (
    <MapContainer
      center={[12.965722114751067, 77.58751150979441]}
      zoom={10}
      style={{ height , width }}
    >
      <TileLayer url={urls.map} />
      {(markers || []).map((marker, index) => (
       <Marker
       key={index}
       position={[marker.lat ?? 12.971599 , marker.lng ?? 77.5946]}
       icon={customIcon}
       >
        </Marker>
      ))}
      <CustomZoomControl />
    </MapContainer>
  );
};

MapComponent.defaultProps = {
  height : "300px",
  width : "100%"
}

export default MapComponent;
