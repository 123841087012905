import {Box, DialogTitle, Typography} from "@mui/material";
import React from "react";
import "./style.scss";
import {DialogHeaderProps} from "../../../interfaces";
import {useSelector} from "react-redux";
import {getText} from "../../../utils";

/**
 * Renders the header of a dialog component.
 *
 * @param zone - The zone of the dialog.
 * @param totalTrees - The total number of trees.
 * @param tag - The tag of the dialog.
 * @param dataCount - The data count of the dialog.
 * @return The header component of the dialog.
 */
const DialogHeader = ({ zone, totalTrees, tag, dataCount }: DialogHeaderProps) => {
const language = useSelector((state:any)=>state.language);
const staticTexts = getText(language);
  return (
    <DialogTitle
      sx={{ m: 0, p: 1.28, "&.MuiDialogTitle-root": { padding: "0px" } }}>
      <Box id="dialog-header" className="custom-dialog-header">
        <Box className="options">
          <Box className="options-content">
            <Typography fontWeight="bold" color="#fff">
              {staticTexts['zone']}
            </Typography>
            <Typography fontWeight="bold" color="#fff">
              {zone}
            </Typography>
          </Box>
          <Box className="options-content">
            <Typography fontWeight="bold" color="#fff">
              #{staticTexts['plantation']}
            </Typography>
            <Typography fontWeight="bold" color="#fff">
              {totalTrees}
            </Typography>
          </Box>
          <Box className="options-content">
            <Typography fontWeight="bold" color="#fff">
              #{tag}
            </Typography>
            <Typography fontWeight="bold" color="#fff">
              {dataCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </DialogTitle>
  );
};

export default DialogHeader;
