import React from "react";
import {pageNotFound} from "../../assets/images";
import {Box, Button, Typography} from "@mui/material";
import "./style.scss";
import {useNavigate} from "react-router-dom";

/**
 * Renders an error element with a page not found message and a button to navigate back to the home page.
 *
 * @return The rendered error element.
 */
const ErrorElement = () => {

  const navigate = useNavigate();

  return (
    <Box id="error-element">
      <img src={pageNotFound} alt="page-not-found" />
      <Typography fontWeight="800" fontSize="32px" marginBottom='1rem'>
        Page not found !
      </Typography>
      <Button onClick={()=>navigate('/')}>Back to home</Button>
    </Box>
  );
};

export default ErrorElement;
