import englishText from '../assets/json/en.json';
import kannadaText from '../assets/json/kn.json';

/**
 * Opens the Apple App Store website in a new browser tab.
 */
export const openAppleStore = () => {
  window.open("https://apps.apple.com/", "_blank");
};

/**
 * Opens the Google Play Store in a new browser window.
 */
export const openPlayStore = () => {
  window.open("https://play.google.com/store", "_blank");
};

/**
 * Checks the length of a given string and truncates it if necessary.
 *
 * @param str - The string to be checked.
 * @return The checked and possibly truncated string.
 */
export const checkStringLength = (str: string) => {
  if (str.length > 15) {
    str = str.substring(0, 35) + '...'
    return str;
  }
  return str;
};

/**
 * Retrieves the text based on the specified language.
 *
 * @param language - The language code.
 * @return The text based on the specified language.
 */
export const getText = (language:string) => {
  if (language === 'en') {
    return englishText;
  } 
    return kannadaText;
};
