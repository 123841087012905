import React, {Fragment} from "react";
import {Outlet} from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

/**
 * Renders the layout of the application.
 */
const Layout = () => {
  return (
    <Fragment>
      <Header />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default Layout;
