import {carouselImage1, carouselImage2, carouselImage3, tree1, tree2, tree3, tree4,} from "../assets/images";

export const cardDetails = [
  {
    imgSrc: tree1,
    trees: 2000,
    zone: "East",
  },
  {
    imgSrc: tree2,
    trees: 1000,
    zone: "West",
  },
  {
    imgSrc: tree3,
    trees: 3000,
    zone: "South",
  },
  {
    imgSrc: tree4,
    trees: 3020,
    zone: "Bommanahalli",
  },
  {
    imgSrc: tree3,
    trees: 3000,
    zone: "Mahadevpura",
  },
  {
    imgSrc: tree1,
    trees: 2000,
    zone: "RR Nagara",
  },
  {
    imgSrc: tree4,
    trees: 3020,
    zone: "Yelahanka",
  },
  {
    imgSrc: tree2,
    trees: 1000,
    zone: "Dasarahalli",
  },
];

export const headerMenus = ["Check My Tree", "Sahaaya", "BBMP"];

export const urls = {
  sahaaya: "https://nammabengaluru.org.in/login",
  bbmp: "https://bbmp.gov.in/home",
  map : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
};

/**
 * Opens the specified URL in a new browser window or tab.
 *
 * @param url - The URL to open.
 */
export const openUrl = (url: string) => {
  window.open(url, "_blank");
};

export const staticTexts = {
  welcome: "Welcome to the Hasiru Rakshaka",
  description1: `The Bangalore Bruhat Mahanagara Palike (BBMP) successfully initiated and concluded the plantation of saplings in Bengaluru across all its zones as part of the flagship project "HASIRU RAKSHAKA". This endeavor was carried out under the guidance of Deputy Chief Minister Shri D.K. Shivkumar.`,
  description2:'  The "HASIRU RAKSHAKA" project aimed to raise environmental awareness among school children, who actively participated in planting and nurturing saplings. This involvement serves as an educational opportunity, imparting knowledge about the environment and emphasizing the crucial role of trees in sustaining life on our planet.',
  description3:'  To ensure ongoing monitoring and management of the planted trees, the BBMP forest department will oversee their health and status through the dedicated "HASIRU RAKSHAKA" website and mobile application. This commitment to ongoing observation underscores the dedication of the initiative to fostering a greener and more sustainable environment in Bengaluru.',
  leaderboard : "Leaderboard",
  topStudents : "Top Students",
  statistics : "Statistics",
  joinAndSupport :"Join and Support",
  hasiruRakshaka : "Hasiru Rakshaka",
  downloadNow : "Download Now!"
};

export const swiperImages = [
  {
    imgSrc: carouselImage1,
    altText: "image-1",
  },
  {
    imgSrc: carouselImage2,
    altText: "image-2",
  },
  {
    imgSrc: carouselImage3,
    altText: "image-3",
  },
  {
    imgSrc: carouselImage1,
    altText: "image-4",
  },
  {
    imgSrc: carouselImage2,
    altText: "image-5",
  },
  {
    imgSrc: carouselImage3,
    altText: "image-6",
  }
];


export const columns = [
  {
    id: "period",
    label: "Period",
  },
  {
    id: "last30days",
    label: "Last 30 days",
  },
  {
    id: "last90days",
    label: "Last 90 days",
  },
];

export const rows = [
  {
    period: "Student feedback",
    last30days: "200",
    last90days: "300",
  },
  {
    period: "Positive",
    last30days: "200",
    last90days: "300",
  },
  {
    period: "Negative",
    last30days: "200",
    last90days: "300",
  },
  {
    period: "Resolved cases",
    last30days: "200",
    last90days: "300",
  },
  {
    period: "Resolved(%)",
    last30days: "75%",
    last90days: "89%",
  },
];
