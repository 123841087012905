import { createBrowserRouter } from "react-router-dom";
import { Homepage, JoinUs, Statistics } from "../pages";
import { Layout, PageNotFound } from "../components";
import AdminLogin from "../pages/Admin";

export default createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    errorElement: <PageNotFound />,
    children : [
      {
        path: "",
        element: <Homepage />,
        errorElement: <PageNotFound />
      },
      {
        path: "join-us",
        element: <JoinUs />,
        errorElement: <PageNotFound />
      },
      {
        path: "statistics",
        element: <Statistics />,
        errorElement: <PageNotFound />,
      },
      {
        path: "report",
        element: <AdminLogin />,
        errorElement: <PageNotFound />,
      },
    ]
  }
]);
