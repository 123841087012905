import { getThirtyDaysStatsReducer,getNinetyDaysStatsReducer, getToppersReducer } from "./stats";
import { combineReducers } from "@reduxjs/toolkit";
import { getZonesReducer } from "./zone"; 
import { getAllZonesPlantMarketReducer, getPlantMarketReducer } from "./plantmarker";
import { getSchoolListReducer } from "./school";
import { getStreetListReducer } from "./street";
import { getLanguageReducer } from "./language";

const reducer = combineReducers({
    thirtyDaysStatistics: getThirtyDaysStatsReducer,
    ninetyDaysStatistics: getNinetyDaysStatsReducer,
    zones: getZonesReducer,
    markers: getPlantMarketReducer,
    schools: getSchoolListReducer,
    streets: getStreetListReducer,
    allZonesMarkers: getAllZonesPlantMarketReducer,
    toppers: getToppersReducer,
    language: getLanguageReducer
});

export { reducer };