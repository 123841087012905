import {httpRequest} from "../network"

/**
 * Retrieves the zones using an HTTP GET request to the "public/zone" endpoint.
 *
 * @return A promise that resolves with the retrieved data or rejects with an error.
 */
const getZones = () => new Promise((resolve, reject) => {
    httpRequest('GET', `public/zone?page=1&limit=10`)
        .then((data) => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
});

export { getZones };
