import React from 'react'
import {Box, CircularProgress} from '@mui/material';

/**
 * Renders a loader component.
 */
const Loader = () => {
    return (
        <Box style={{ position: 'absolute', top: '50%', backgroundColor:'rgba(0,0,0,0.5)', left: '50%', transform: 'translate(-50%, -50%)', zIndex:"1000",}} height={"100%"} width={"100vw"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress color='success'/>
        </Box>
    )
}

export default Loader;
